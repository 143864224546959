import {APP_API_URL} from "../core/core";

export const userSignIn = user => {
    return fetch(`${APP_API_URL}/auth/login`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(user)
    })
        .then(response => {
            if (response.ok) {
                return response.json();
            }
            return `${response.statusText}`;
        })
        .then(data => {
            return data;
        })
        .catch(error => console.log('[userSignIn error]: ', error));
};

export const userSignOut = token => {
    return fetch(`${APP_API_URL}/signup/logout`, {
        method: 'GET',
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
        .then(response => {
            if (response.ok) {
                return response.json();
            }
            return `${response.statusText}`;
        })
        .then(data => {
            return data;
        })
        .catch(error => console.log(error));
};
