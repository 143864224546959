import 'react-app-polyfill/ie9'; // For IE 9-11 support
import 'react-app-polyfill/ie11'; // For IE 11 support
import './polyfill';
import React from 'react';
import { createRoot } from 'react-dom/client';
import AccountProvider from './providers/AccountProvider';
import App from './App';
import * as serviceWorker from './serviceWorker';

const root = createRoot(document.getElementById('root'));

const HelloWorld = () => {
  return (<AccountProvider>
            <App />
          </AccountProvider>)
}

root.render(<HelloWorld />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
